@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

html {
  user-select: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  /* --main-green: rgb(141, 199, 47);
  --main-blue: rgb(86, 186, 216);
  --main-grey: rgb(250, 250, 250);
  --main-yellow: rgb(225,214,32); */
  
  /* --c1: #feffdf;
  --c2: #dde0ab;
  --c3: #97cba9;
  --c4: #668ba4; */
  
  --c1: #eeeeee;
  --c2: #acc6aa;
  --c3: #71a0a5;
  --c4: #77628c;
}
body {
  padding: 0;
  margin: 0;
}
